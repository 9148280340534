<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container>
      <v-row>
        <v-col cols="2">
          <h2>協会一覧</h2>
        </v-col>
          <v-col cols="2">
            <v-select
                :items="associationType"
                dense
                v-model="searchCondition.type"
                :rules="[]"
                :readonly="attribute == 1"
                :disabled="loading"
                v-on:change="loadList()"
              ></v-select>
          </v-col>
          <v-col cols="1">
          </v-col>
          <v-col cols="3">
            <v-text-field label="検索"
                v-model="searchCondition.office_name" 
                dense 
                :rules="[maxLength(50)]" 
                :disabled="loading"
                :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
          </v-col>
          <!-- 新規協会登録はしない -->
          <!-- <v-col cols="2">
            <v-btn small class="primary" 
                :height="40"
                :disabled="loading" 
                @click="goto_detail('new', '')"
            >         
              <v-icon left>mdi-plus-circle</v-icon>
              新規協会登録
            </v-btn>
          </v-col> -->
      </v-row>
      
      <v-row>
        <v-col cols="12">
          <v-data-table
                :headers="headers"
                :items="memberList"
                :disable-pagination='true'
                :hide-default-footer='true'
                :fixed-header='true'
                height="calc(100vh - 200px)"
                dense
                :search="searchCondition.office_name"
                :loading="loading"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.sid" @dblclick="goto_detail('edit', item.associationId)">
                <td>{{ item.associationId }}</td>
                <td>{{ item.associationName }}</td>
                <td>{{ replacePrefIdToPrefName(item.prefId) }}</td>
                <td>{{ item.address }}</td>
                <td class="center min-width-200 flex">
                  <v-btn small class="primary" :height="20" :width="50"
                       :disabled="loading" @click="goto_detail('edit', item.associationId)"
                  >
                    編集
                  </v-btn>
                  <!-- 削除はできない -->
                  <!-- <v-btn small class="secondary" :height="20" :width="50"
                        :disabled="loading" @click="goto_detail('delete', item.associationId)"
                  >
                    削除
                  </v-btn> -->
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Debug from "../Lib/Debug";
import Message from "../Lib/Message";
import Mixin from "../mixins/const.js";

import Association from "../model/m_association";

export default {
  mixins: [Mixin],

  data: () => ({
    valid: true,
    loading: false,

    show: true,

    memberNo: '',
    attribute: 0,

    // テーブルヘッダー
    headers: [
      { text: "会員ID", align: "left", sortable: true, value: "associationId", width: "120px" },
      { text: "協会名", align: "left", sortable: true, value: "associationName", width: "400px" },
      { text: "都道府県", align: "left", sortable: true, value: "prefId", width: "100px" },
      { text: "住所", align: "left", sortable: true, value: "address", width: "400px" },
      { text: "", align: "center", sortable: false, width: "300px" },
      { text: "", align: "center", sortable: false },
    ],
    memberList: [], // 会員データ一覧
    searchCondition: {
      associationId: null,
      type: 0,
      officeName: "",
    },// 検索条件
  }),

  // ライフサイクルフック
  created: function () {
  },

  mounted: function() {
    this.attribute = this.$store.getters.getAttribute;  // 属性
    if(this.attribute == 0) {
      // 事業所ユーザ
      this.memberNo = this.$store.getters.getMemberNo;    // 会員番号
    } else {
      // 協会ユーザ
      this.memberNo = this.$store.getters.getAssociation.association_id;    // 会員番号
    }
 
    // 属性による検索条件の設定
    if(this.attribute == 1) {
      // 都道府県協会ユーザは自協会のデータのみ
      const association = this.$store.getters.getAssociation;
      this.searchCondition.type = association.type;
      this.searchCondition.associationId = this.memberNo;
    }

    this.loadList();
  },

  methods: {

    /** 表示更新 */
    async loadList() {
      Debug.log("function[loadList]");

      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      Debug.log(this.searchCondition);

      this.loading = true;
      // DBから協会一覧取得
      Association.search(this.searchCondition)
        .then(res => {
          if (res && res.data) {
            this.memberList = res.data;
            Debug.log(this.memberList);
          }
        })
        .catch((err) => {
          Message.err(err, "協会一覧を取得できませんでした");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /** 詳細ページに遷移 */
    goto_detail(mode, associationId) {
      Debug.log("function[goto_detail]");
      Debug.log(this.searchCondition);

      this.$store.commit('setStoreSearchCondition', this.searchCondition);

      this.$store.commit("setMode", mode); // 新規 or 編集 or 削除をstoreに保存
      this.$store.commit("setEditId", associationId); // 編集 or 削除をクリックした会員IDをstoreに保存

      // 編集画面へ遷移
      this.$router.push("/association_edit/");
    },
  },

};
</script>

<style scoped>

.v-data-table >>> .flex {
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: center;
}

</style>
